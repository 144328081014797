.html,
body {
  padding: 0;
  margin: 0;
  font-family: "Lato", sans-serif;
  font-family: "Noto Sans", sans-serif;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: white;
}
